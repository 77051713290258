import React from 'react'
import { EmployerRule, EmployerRuleCreationPayload } from '../../api/employer-rules'
import { Field, Form, Formik, FieldArray } from 'formik'
import InputField from '../form/fields/input'
import SelectField from '../form/fields/select'
import {
  EmployerDayRuleInterval,
  EmployerWeekRuleInterval,
} from '../../../../backend/src/services/resources/employer-rules/employer-rule.model'
import { useCreateMutation, useUpdateMutation } from '../../queries/employer-rules'
import { RequestButton } from '../request-components/request-components'
import { Columns, Element, Heading, Button, Icon } from 'react-bulma-components'
import CheckboxField from '../form/fields/checkbox'
import { useNavigate } from 'react-router-dom'
import { oneHour, dateToDateInputValue, dateInputToDateValue } from '../../utils/date'
import locales from '../../../../locales/employer-rules'
import { RemoveIcon } from '../icons'

interface EmployerRulesFormProps {
  employerRule?: EmployerRule
  employerId: string
}

type FormValues = Partial<Omit<EmployerRuleCreationPayload, 'interval' | 'valuesInTime'>> & {
  interval: Partial<Omit<EmployerRuleCreationPayload['interval'], 'min' | 'max'>> & {
    min?: string
    max?: string
  }
  valuesInTime: Array<{
    value: string | number
    effectiveFrom: string
    effectiveTo?: string
  }>
}

const EmployerRulesForm: React.FC<EmployerRulesFormProps> = ({ employerRule, employerId }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (createMutation.isSuccess)
      navigate(`/employers/${employerId}/rules/${createMutation.data.data._id}`)
  }, [createMutation.isSuccess])

  return (
    <Formik<FormValues>
      initialValues={
        employerRule
          ? {
              ...employerRule,
              interval: {
                ...employerRule.interval,
                min: employerRule.interval.min / (60 * 1000),
                max: employerRule.interval.max && employerRule.interval.max / (60 * 1000),
              },
              valuesInTime: employerRule.valuesInTime.map(vt => ({
                value: vt.value,
                effectiveFrom: dateToDateInputValue(new Date(vt.effectiveFrom)),
                effectiveTo: vt.effectiveTo
                  ? dateToDateInputValue(new Date(vt.effectiveTo))
                  : undefined,
              })),
            }
          : {
              name: '',
              employer: employerId,
              employerDataOptions: {
                setDurationAsProportional: false,
              },
              kind: 'add',
              valuesInTime: [
                {
                  value: '',
                  effectiveFrom: dateToDateInputValue(new Date()),
                  effectiveTo: '',
                },
              ],
              interval: {
                name: '',
                period: '',
                input: '',
                min: '',
                max: '',
              },
              display: {
                summary: {
                  totals: true,
                  costs: true,
                  days: true,
                },
                payroll: true,
              },
            }
      }
      enableReinitialize
      validate={values => {
        const errors: Record<string, string> = {}

        // Skip validation if no values
        if (!values.valuesInTime?.length) {
          errors['valuesInTime'] = 'Au moins une période est requise'
          return errors
        }

        // Sort valuesInTime by start date to make validation easier
        const sortedValues = [...values.valuesInTime].sort(
          (a, b) => new Date(a.effectiveFrom).getTime() - new Date(b.effectiveFrom).getTime(),
        )

        // Check if we have more than one period without end date
        const periodsWithoutEndDate = values.valuesInTime.filter(vt => !vt.effectiveTo)
        if (periodsWithoutEndDate.length > 1) {
          periodsWithoutEndDate.forEach(period => {
            const index = values.valuesInTime.indexOf(period)
            errors[`valuesInTime.${index}.effectiveTo`] =
              'Une seule période peut être sans date de fin'
          })
        }

        // Check if the entry without end date is the most recent one
        const nullEndDateIndex = sortedValues.findIndex(vt => !vt.effectiveTo)
        if (nullEndDateIndex !== -1 && nullEndDateIndex !== sortedValues.length - 1) {
          const originalIndex = values.valuesInTime.indexOf(sortedValues[nullEndDateIndex])
          errors[`valuesInTime.${originalIndex}.effectiveTo`] =
            'La période sans date de fin doit être la plus récente'
        }

        // Check for overlaps and other validations
        sortedValues.forEach((vt, index) => {
          const currentIndex = values.valuesInTime.indexOf(sortedValues[index])

          // Basic required field validations with better type checking
          if (!vt.value || isNaN(Number(vt.value))) {
            errors[`valuesInTime.${currentIndex}.value`] = 'Valeur numérique requise'
          }

          if (!vt.effectiveFrom) {
            errors[`valuesInTime.${currentIndex}.effectiveFrom`] = 'Date de début requise'
          } else {
            // Validate date format
            const fromDate = new Date(vt.effectiveFrom)
            if (fromDate.toString() === 'Invalid Date') {
              errors[`valuesInTime.${currentIndex}.effectiveFrom`] = 'Date invalide'
            }
          }

          if (vt.effectiveTo) {
            // Validate date format
            const toDate = new Date(vt.effectiveTo)
            if (toDate.toString() === 'Invalid Date') {
              errors[`valuesInTime.${currentIndex}.effectiveTo`] = 'Date invalide'
            }

            // Check if end date is after start date
            if (vt.effectiveFrom && vt.effectiveTo < vt.effectiveFrom) {
              errors[`valuesInTime.${currentIndex}.effectiveTo`] =
                'La date de fin doit être après la date de début'
            }
          }

          // Check for overlaps with subsequent periods
          for (let j = index + 1; j < sortedValues.length; j++) {
            const nextVt = sortedValues[j]
            const currentEndDate = vt.effectiveTo ? new Date(vt.effectiveTo) : null
            const nextStartDate = new Date(nextVt.effectiveFrom)

            // If current period has no end date, it automatically overlaps with all subsequent periods
            if (!currentEndDate) {
              errors[`valuesInTime.${currentIndex}.effectiveTo`] =
                'Cette période chevauche les périodes suivantes'
              break
            }

            // If current period's end date is after or equal to next period's start date
            if (currentEndDate >= nextStartDate) {
              errors[`valuesInTime.${currentIndex}.effectiveTo`] =
                'Cette période chevauche la période suivante'
              break
            }

            // Check for gaps between periods (more than 24 hours)
            const gapInHours = (nextStartDate.getTime() - currentEndDate.getTime()) / oneHour
            if (gapInHours > 24) {
              errors[`valuesInTime.${currentIndex}.effectiveTo`] =
                'Il ne doit pas y avoir de trou de plus de 24h entre les périodes'
              break
            }
          }
        })

        // If we have only one period, it should not have an end date
        if (values.valuesInTime.length === 1 && values.valuesInTime[0].effectiveTo) {
          errors['valuesInTime.0.effectiveTo'] =
            'La première période ne devrait pas avoir de date de fin'
        }

        return errors
      }}
      onSubmit={values => {
        const payload = {
          ...values,
          interval: {
            ...values.interval,
            name: values.name,
            min: Number(values.interval.min) * 60 * 1000,
            max: Number(values.interval.max) * 60 * 1000,
          },
          valuesInTime: values.valuesInTime.map(vt => ({
            value: Number(vt.value),
            effectiveFrom: dateInputToDateValue(vt.effectiveFrom),
            effectiveTo: vt.effectiveTo ? dateInputToDateValue(vt.effectiveTo) : undefined,
          })),
        }

        if (!employerRule) {
          createMutation.mutate(payload)
        } else {
          updateMutation.mutate({
            id: employerRule._id,
            ...payload,
          })
        }
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form autoComplete="off">
            <Field label="Nom " name="name" component={InputField} required />
            <Field
              label="Nom court"
              name="shortName"
              component={InputField}
              required
              help={'Utilisé pour les entêtes des colonnes dans les récapitulatifs'}
            />
            <>
              {' '}
              <Field
                label="Mode de calcul"
                name="kind"
                component={SelectField}
                options={[
                  { value: 'add', label: "Ajout d'une valeur fixe pour chaque heure" },
                  {
                    value: 'multiply',
                    label: 'Multiplication du coût horaire (pourcentage) pour chaque heure',
                  },
                  {
                    value: 'goal',
                    label: "Prime - valeur ajoutée quand l'objectif est atteint",
                  },
                ]}
                required
                fullwidth
              />
              <Heading size={5} mt={5}>
                Données pour le calcul
                <p className="help mb-4">
                  Les périodes doivent être continues et sans chevauchement. Si une période est sans
                  date de fin, elle doit être la plus récente.
                </p>
              </Heading>
              <FieldArray name="valuesInTime">
                {({ push, remove }) => (
                  <Columns.Column size={12}>
                    {values.valuesInTime.map((_, index) => (
                      <Columns key={index}>
                        <Columns.Column size={3}>
                          <Field
                            label="Valeur"
                            name={`valuesInTime.${index}.value`}
                            component={InputField}
                            type="number"
                            required
                          />
                        </Columns.Column>
                        <Columns.Column size={4}>
                          <Field
                            label="Date de début"
                            name={`valuesInTime.${index}.effectiveFrom`}
                            component={InputField}
                            type="date"
                            required
                          />
                        </Columns.Column>
                        <Columns.Column size={4}>
                          <Field
                            label={`Date de fin ${
                              index === values.valuesInTime.length - 1 ? '(optionnel)' : ''
                            }`}
                            name={`valuesInTime.${index}.effectiveTo`}
                            component={InputField}
                            type="date"
                          />
                        </Columns.Column>
                        <Columns.Column size={1}>
                          {values.valuesInTime.length > 1 && (
                            <Button
                              color="danger"
                              size="small"
                              onClick={() => remove(index)}
                              style={{ marginTop: '2.2rem' }}
                            >
                              <Icon>
                                <RemoveIcon color="white" />
                              </Icon>
                            </Button>
                          )}
                        </Columns.Column>
                      </Columns>
                    ))}
                    <Button
                      onClick={() =>
                        push({
                          value: '',
                          effectiveFrom: dateToDateInputValue(new Date()),
                          effectiveTo: '',
                        })
                      }
                    >
                      Ajouter une période
                    </Button>
                  </Columns.Column>
                )}
              </FieldArray>
              <Columns.Column size={6}>
                <Field
                  label="Période prise en compte"
                  name="interval.period"
                  fullwidth
                  component={SelectField}
                  options={[
                    { value: 'day', label: 'Chaque jours de la semaine' },
                    { value: 'week', label: 'Semaine complète' },
                  ]}
                  required
                />
              </Columns.Column>
              <Columns.Column size={6}>
                <Field
                  label="Valeur prise en compte (input)"
                  name="interval.input"
                  fullwidth
                  component={SelectField}
                  disabled={!values.interval?.period}
                  options={
                    values.interval.period === 'day'
                      ? dayIntervalInputs
                      : values.interval.period === 'week'
                      ? weekIntervalInputs
                      : []
                  }
                  required
                />
              </Columns.Column>
              <Columns.Column size={6}>
                <Field
                  label="Règle appliquée à partir de"
                  name="interval.min"
                  component={InputField}
                  type="number"
                  required
                  help="En minutes"
                />
              </Columns.Column>
              <Columns.Column size={6}>
                <Field
                  label="Règle appliquée jusqu'à"
                  name="interval.max"
                  component={InputField}
                  type="number"
                  help="En minutes"
                />
              </Columns.Column>
              {values.kind === 'multiply' && (
                <Columns.Column size={12}>
                  <Field
                    label="Enregistrer la durée comme différence de la multiplication"
                    name="employerDataOptions.setDurationAsProportional"
                    component={CheckboxField}
                    help={
                      <Element>
                        Exemple
                        <br />
                        Non: La durée enregistrée est égale à la durée travaillée dans la période de
                        la règle.
                        <br />
                        Oui: Une heure * {values.valuesInTime[0]?.value || '1.5'} ={' '}
                        {(
                          (oneHour * (Number(values.valuesInTime[0]?.value) || 1.5) - oneHour) /
                          1000 /
                          60
                        ).toFixed(2)}{' '}
                        minutes
                      </Element>
                    }
                  />
                </Columns.Column>
              )}
            </>

            <Heading size={5} mt={5}>
              Paramètres d'affichage - Récapitulatif
            </Heading>
            <Columns>
              <Columns.Column size={4}>
                <Field
                  label="Afficher dans les totaux"
                  name="display.summary.totals"
                  component={CheckboxField}
                />
              </Columns.Column>
              <Columns.Column size={4}>
                <Field
                  label="Afficher dans les coûts"
                  name="display.summary.costs"
                  component={CheckboxField}
                />
              </Columns.Column>
              {values.interval.period === 'day' && (
                <Columns.Column size={4}>
                  <Field
                    label="Afficher dans les journées"
                    name="display.summary.days"
                    component={CheckboxField}
                  />
                </Columns.Column>
              )}
            </Columns>

            <Heading size={5} mt={5}>
              Paramètres d'affichage - Vue Paie
            </Heading>
            <Columns>
              <Columns.Column size={6}>
                <Field
                  label="Visible dans la vue Paie"
                  name="display.payroll"
                  component={CheckboxField}
                />
              </Columns.Column>
            </Columns>

            <RequestButton
              color="primary"
              type="submit"
              mutation={!employerRule ? createMutation : updateMutation}
              mt={6}
            >
              {employerRule ? 'Mettre à jour' : 'Créer'}
            </RequestButton>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EmployerRulesForm

const weekIntervalInputs: { value: EmployerWeekRuleInterval['input']; label: string }[] =
  Object.keys(locales.inputs.week).map(key => ({
    value: key,
    label: locales.inputs.week[key] as string,
  }))

const dayIntervalInputs: { value: EmployerDayRuleInterval['input']; label: string }[] = Object.keys(
  locales.inputs.day,
).map(key => ({
  value: key,
  label: locales.inputs.day[key] as string,
}))
